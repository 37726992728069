@if (modalState) {
    <app-modal (closeModal)="handleCloseModal()">
        <div class="flex flex-col items-center space-y-7">
            <h4>Think about licensing</h4>
            <p class="text-base max-w-lg">
                DEHNproject gives you the most, if you use it with a DEHNplan and / or a DEHNrisk license. Lorem ipsum dolor sit amet
                consectetur. Tristique scelerisque tellus posuere platea etiam. Lacus quam risus sed ultricies nisi at diam enim pretium.
            </p>
            <app-button type="primary" (click)="handleLicenseButtonClick()">Buy a license</app-button>
        </div>
    </app-modal>
}
