import { Injectable, Signal, computed, signal } from '@angular/core';
import { PageSize, PageInfo, PaginationData } from '.';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    private pageSignal = signal<number>(1);
    private itemsPerPageSignal = signal<PageSize>(5);
    private totalItems = 0;

    /** Computed signals */
    private currentPageInfoSignal: Signal<PageInfo> = computed(() => {
        return {
            page: this.pageSignal(),
            size: this.itemsPerPageSignal()
        };
    });
    private totalPagesSignal: Signal<number> = computed(() => Math.ceil(this.totalItems / this.itemsPerPageSignal()));
    private currentPaginationData: Signal<PaginationData> = computed(() => {
        return {
            totalPages: this.totalPagesSignal(),
            currentPageData: this.currentPageInfoSignal(),
            visiblePages: this.calculateVisiblePages(this.totalPagesSignal(), this.pageSignal())
        };
    });

    //* PUBLIC (API) SIGNALS *//
    /**
     * @returns - The current page as Signal<number>.
     */
    currentPageInfo: Signal<PageInfo> = this.currentPageInfoSignal;
    /**
     * @returns - The current pagination data as Signal<PaginationData>.
     */
    paginationData: Signal<PaginationData> = this.currentPaginationData;

    /**
     * @param page - The new page to go to.
     * @description - Set the page emitted by the pagination component.
     */
    goToPage(page: number): void {
        this.pageSignal.set(page);
    }

    /**
     * @param newItemsPerPage - The new items per page to set.
     * @description - Set the items per page emitted by the pagination component and update the page accordingly.
     */
    handleItemsPerPageChange(newItemsPerPage: PageSize): void {
        this.pageSignal.update((currentPage) => {
            const newPage = Math.ceil((currentPage * this.itemsPerPageSignal()) / newItemsPerPage);
            return newPage > this.totalPagesSignal() ? this.totalPagesSignal() : newPage;
        });
        this.itemsPerPageSignal.set(newItemsPerPage);
    }

    /**
     * @description - Set the total number of items emitted by the pagination component.
     * @param totalItems - The total number of items.
     */
    setTotalItems(totalItems: number): void {
        this.totalItems = totalItems;
    }

    /**
     * @param totalPages - The total number of pages
     * @param currentPage - The current page
     * @returns - The array of visible pages to render
     */
    private calculateVisiblePages(totalPages: number, currentPage: number): number[] {
        let visiblePages: number[] = [];

        if (totalPages <= 5) {
            visiblePages = Array.from({ length: totalPages }, (_, i) => i + 1);
        } else if (currentPage <= 3) {
            visiblePages = [1, 2, 3, 0, totalPages];
        } else if (currentPage >= totalPages - 2) {
            visiblePages = [1, 0, totalPages - 2, totalPages - 1, totalPages];
        } else {
            visiblePages = [1, 0, currentPage, 0, totalPages];
        }

        return visiblePages;
    }
}
