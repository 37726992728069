import { Languages } from '../../shared/constants';

export interface LanguageInfo {
    id: string;
    name: string;
    key: string;
}

export const LANGUAGES_OPTIONS: LanguageInfo[] = [
    { id: Languages.GERMAN, name: 'Deutsch', key: 'DE' },
    { id: Languages.ENGLISH, name: 'English', key: 'EN' },
    { id: Languages.ITALIAN, name: 'Italiano', key: 'IT' },
    { id: Languages.SPANISH, name: 'Español', key: 'ES' },
    { id: Languages.FRENCH, name: 'Français', key: 'FR' }
];
