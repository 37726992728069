import { Component, EventEmitter, OnInit, Output, input, signal } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { ClickOutsideDirective } from '../../directives';

@Component({
    standalone: true,
    imports: [ButtonComponent, ClickOutsideDirective],
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html'
})
export class DropdownButtonComponent<T> implements OnInit {
    dropdownOptions = input.required<T[]>({
        alias: 'options'
    });
    selectedOption = signal<T | undefined>(undefined);

    @Output() selectedOptionResponse: EventEmitter<T> = new EventEmitter<T>();

    isOpen = signal<boolean>(false);

    ngOnInit(): void {
        this.selectedOption.set(this.dropdownOptions()[0]);
    }

    /**
     * @description - Toggle the dropdown menu state.
     */
    toggleDropdown(): void {
        this.isOpen.set(!this.isOpen());
    }

    /**
     * @param option - The option to select.
     */
    selectOption(option: T): void {
        this.selectedOption.set(option);

        this.isOpen.set(false);
        this.selectedOptionResponse.emit(this.selectedOption());
    }

    /**
     * @description - Close the dropdown menu when the user clicks outside of it.
     */
    clickedOutside(): void {
        this.isOpen.set(false);
    }
}
