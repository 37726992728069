import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/shared/components';
import { HamburgerMenuService } from '../../services/hamburger-menu/hamburger-menu.service';
import { NavSections } from './constants/nav-sections';
import { NavItemComponent } from './nav-item/nav-item.component';

@Component({
    standalone: true,
    imports: [NavItemComponent, ButtonComponent, TranslateModule],
    selector: 'app-hamburger-menu',
    templateUrl: './hamburger-menu.component.html'
})
export class HamburgerMenuComponent {
    NavSections = NavSections;
    sectionsNames: string[];

    private hamburgerMenuService = inject(HamburgerMenuService);

    isSubMenuOpen = computed(() => this.hamburgerMenuService.subMenuStateValue());
    subMenuName = computed(() => this.hamburgerMenuService.selectedSubMenuValue());

    constructor() {
        this.sectionsNames = Object.keys(NavSections.sections).filter((sectionName: string) => !sectionName.includes('SubMenu'));
    }

    /**
     * @description - Toggle the hamburger menu.
     */
    toggleHamburgerMenu(): void {
        this.hamburgerMenuService.closeSubMenu();
        this.hamburgerMenuService.toggle();
    }
}
