<div class="relative inline-block" appClickOutside (clickOutside)="clickedOutside()">
    <app-button
        type="secondary"
        size="small"
        icon="arrow"
        iconSize="tiny"
        [iconDirection]="isDropdownOpen() ? 'up' : 'down'"
        (click)="toggleDropdown()"
    >
        {{ selectedLanguage().key }}
    </app-button>

    @if (isDropdownOpen()) {
        <div class="absolute bottom-full left-0 z-10 block translate-y-[-1%] bg-primary-realwhite shadow-lg">
            <div class="flex flex-col hover:cursor-pointer">
                @for (language of languageOptions; track language.key) {
                    <div
                        class="px-6 py-3 text-secondary-text hover:bg-secondary-gainsboro hover:text-primary-red"
                        (click)="changeLanguage(language.key)"
                    >
                        {{ language.name }}
                    </div>
                }
            </div>
        </div>
    }
</div>
