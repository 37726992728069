import { Component, computed, inject, input, signal } from '@angular/core';
import { TableHeader } from 'src/app/shared/interfaces';
import { SortingTableService } from 'src/app/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
    standalone: true,
    imports: [SvgIconComponent, TranslateModule],
    selector: 'app-table-header',
    templateUrl: './table-header.component.html'
})
export class TableHeaderComponent {
    header = input.required<TableHeader>();

    arrowIconColor = '#dee3e3';
    isHeaderHovered = signal<boolean>(false);

    private sortingTableService = inject(SortingTableService);

    sortingState = computed(() => this.sortingTableService.sortingInfo());

    /**
     * @description - Sets the isHeaderHovered property to true when the mouse enters the header to show the sorting icon
     */
    onHeaderMouseEnter(): void {
        this.isHeaderHovered.set(true);
    }

    /**
     * @description Sets the isHeaderHovered property to false when the mouse leaves the header to hide the sorting icon
     */
    onHeaderMouseLeave(): void {
        this.isHeaderHovered.set(false);
    }

    /**
     * @returns - Whether the header is already sorted or not
     */
    get isHeaderSorted(): boolean {
        return this.sortingState().sort === this.header().sortableKey;
    }

    /**
     * @description - Toggles the sorting info of the table
     */
    handleSwitchSortingInfo(): void {
        this.sortingTableService.switchSortingInfo(this.header().sortableKey!);
    }
}
