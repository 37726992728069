import { Logger } from '@datadog/browser-logs';
import { environment } from 'src/environments/environment';

export class LoggerService {
    constructor(
        private readonly logger: Logger,
        private readonly context: string
    ) {}

    debug(message: string, messageContext?: object): void {
        if (!environment.production) {
            this.logConsoleInfo(message, messageContext);
        }

        if (environment.systemname !== 'local') {
            this.logger.debug(message, messageContext);
        }
    }

    info(message: string, messageContext?: object): void {
        if (!environment.production) {
            this.logConsoleInfo(message, messageContext);
        }

        if (environment.systemname !== 'local') {
            this.logger.info(message, messageContext);
        }
    }

    warn(message: string, messageContext?: object): void {
        console.warn(message, messageContext);

        if (environment.systemname !== 'local') {
            this.logger.warn(message, messageContext);
        }
    }

    error(message: string, messageContext?: object): void {
        console.error(message, messageContext);

        if (environment.systemname !== 'local') {
            this.logger.error(message, messageContext);
        }
    }

    private logConsoleInfo(message: string, messageContext?: object) {
        if (messageContext) {
            console.info(message, { context: this.context, ...messageContext });
        } else {
            console.info(message, { context: this.context });
        }
    }
}
