<div
    [@modalEnterAnimation]
    id="info-note-modal"
    class="fixed left-[40%] top-[40%] z-50 justify-center overflow-y-auto bg-primary-realwhite px-8 py-7"
>
    <div id="info-note-modal-content" class="flex max-h-[50vh] min-w-[20rem] max-w-80 flex-col space-y-5">
        <!--Modal heading section container-->
        <div class="flex flex-col gap-y-2">
            <!--First row container-->
            <div class="flex justify-between gap-8">
                <h4 id="message-title" class="m-0 break-all text-xl">{{ note?.title }}</h4>
                <!--Actions button container-->
                <div class="flex gap-2">
                    <app-button type="secondary" icon="trash" iconSize="tiny" size="small" [knob]="true" (click)="closeModal(true)">
                    </app-button>
                    <app-button
                        type="secondary"
                        icon="cancel"
                        iconSize="tiny"
                        size="small"
                        [knob]="true"
                        (click)="closeModal(false)"
                    ></app-button>
                </div>
            </div>

            <p id="message-time" class="m-0 text-xs">{{ note?._ts }}</p>
        </div>
        <p id="note-modal-body-content">{{ note?.content }}</p>
    </div>
</div>
