import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TableData, TableHeader } from '../../interfaces';
import { SpinnerLoaderComponent } from '../spinner-loader/spinner-loader.component';
import { ContextMenuTriggerComponent } from './context-menu-trigger/context-menu-trigger.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { NgClass } from '@angular/common';

const rowsAnimation = trigger('rowsAnimation', [
    transition(':enter', [
        query('tr', [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger(-30, [animate('400ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: 'none', opacity: 1 }))])
        ])
    ])
]);

@Component({
    standalone: true,
    imports: [
        NgClass,
        SearchboxComponent,
        PaginationComponent,
        TableCellComponent,
        ContextMenuTriggerComponent,
        TableHeaderComponent,
        SpinnerLoaderComponent,
        TranslateModule
    ],
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    animations: [rowsAnimation]
})
export class TableComponent {
    headers = input.required<TableHeader[]>();
    tableData = input.required<TableData[]>({
        alias: 'dataSource'
    });
    items = input.required<number>({
        alias: 'totalItems'
    });
    pagination = input.required<boolean>();
    searchBox = input.required<boolean>();
    title = input.required<string>({
        alias: 'tableTitle'
    });
}
