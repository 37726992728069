import { ChangeDetectionStrategy, Component, OnInit, inject, input, signal } from '@angular/core';
import { ImageService } from 'src/app/core/services';
import { AssetsPath } from 'src/app/layout/constants';
import { delay, first, tap } from 'rxjs';
import { NgOptimizedImage } from '@angular/common';
import { ImageSource, ImageType } from '.';

@Component({
    standalone: true,
    imports: [NgOptimizedImage],
    selector: 'app-image',
    templateUrl: './image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnInit {
    imageType = input.required<ImageSource>({
        alias: 'type'
    });
    imageSource = input.required<string>({
        alias: 'src'
    });
    altText = input<string>('image-alt-text');
    width = input<number>(50);
    height = input<number>(50);
    customStyles = input<string>('');
    imageContent = signal<string>('');

    private readonly imageService = inject(ImageService);

    ngOnInit() {
        if (this.imageType() === ImageType.BASE64) {
            this.fetchData(this.imageSource());
        } else {
            this.imageContent.set(AssetsPath.IMAGES + this.imageSource());
        }
    }

    /**
     * @param base64 - The base64 string to convert to a blob URL
     * @description - Converts a base64 string to a blob URL
     */
    private fetchData(base64: string): void {
        const blobUrlImage$ = this.imageService.dataURIToBlob(base64).pipe(
            delay(0),
            tap((blobURL) => this.imageContent.set(blobURL)),
            first()
        );

        blobUrlImage$.subscribe();
    }
}
