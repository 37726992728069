import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HamburgerMenuService } from 'src/app/layout/services';
import { SvgIconComponent } from 'src/app/shared/components';
import { LinkBuilder } from 'src/app/shared/utils/LinkBuilderUtils/link-builder.utils';
import { NavItem } from '../constants/nav-sections';

@Component({
    standalone: true,
    imports: [NgClass, SvgIconComponent, TranslateModule],
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html'
})
export class NavItemComponent {
    sectionsArray = input.required<NavItem[]>({
        alias: 'sections'
    });

    private readonly hamburgerMenuService = inject(HamburgerMenuService);

    /**
     * @param item - Method for handle the nav item that was clicked by the user.
     */
    handleClick(item: NavItem): void {
        if (item?.subMenuName) {
            this.hamburgerMenuService.switchSubMenu(item.subMenuName);
        }
    }

    getUrl(item: NavItem): string {
        return item?.subMenuName ? '' : LinkBuilder.redirectToLegacyProject(item.key);
    }

    /**
     * @param item - The nav item to get the classes for.
     * @returns - The classes for the nav item according to the item key and if it is a sub menu or not.
     */
    itemConditionalClasses(item: NavItem) {
        return {
            'pb-0 pt-2': !item.isSubMenu,
            'text-secondary-eerieblack border-b-secondary-gainsboro': item.key !== 'dashboard',
            'text-primary-red border-b-primary-red': item.key === 'dashboard'
        };
    }
}
