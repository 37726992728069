import { ChangeDetectionStrategy, Component, Signal, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from 'src/app/shared/components';
import { DashboardHeaders } from '../../shared/constants/table-headers';
import { DataResponse, TableHeader } from '../../shared/interfaces';
import { History, NotificationData } from '../interfaces';
import { HistoryService, NotificationService } from '../services';
import { InfoNotesContainerComponent } from './info-notes-container/info-notes-container.component';
import { LoaderContainerComponent } from './loader-container/loader-container.component';

@Component({
    standalone: true,
    imports: [TableComponent, InfoNotesContainerComponent, LoaderContainerComponent, TranslateModule],
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
    historyService = inject(HistoryService);
    notificationService = inject(NotificationService);

    headers: TableHeader[] = DashboardHeaders;
    historyData: Signal<DataResponse<History>> = this.historyService.historyData;
    notificationData: Signal<NotificationData> = this.notificationService.notificationsData;
}
