import { Component, OnInit, inject, input, signal } from '@angular/core';
import { DatadogService, LoggerService } from 'src/app/core/services';
import { DropdownTriggerForDirective } from 'src/app/shared/directives';
import { TableData } from 'src/app/shared/interfaces';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LinkBuilder } from 'src/app/shared/utils/LinkBuilderUtils/link-builder.utils';
import { ContextMenuOptions } from '.';
import { DropMenuComponent } from '../../drop-menu/drop-menu.component';
import { ImageComponent } from '../../image/image.component';
import { ModalComponent } from '../../modal/modal.component';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
    standalone: true,
    imports: [SvgIconComponent, ModalComponent, DropMenuComponent, ImageComponent, DropdownTriggerForDirective],
    selector: 'app-context-menu-trigger',
    templateUrl: './context-menu-trigger.component.html'
})
export class ContextMenuTriggerComponent implements OnInit {
    row = input.required<TableData>();

    contextMenuOptions = [ContextMenuOptions.OPEN];
    modalState = signal<boolean>(false);
    private readonly logger!: LoggerService;

    private readonly blurOverlayService = inject(OverlayService);
    private readonly datadogService = inject(DatadogService);

    constructor() {
        this.logger = this.datadogService.createLogger('context-menu-trigger');
    }

    ngOnInit(): void {
        if (this.row()?.previewImage) {
            this.contextMenuOptions.push(ContextMenuOptions.PREVIEW);
        }
    }

    /**
     * @param selectedOption - The option selected from the drop menu passed from child component, used to determine which action to take
     */
    handleSelectedOption(selectedOption: string) {
        if (selectedOption === ContextMenuOptions.OPEN) {
            LinkBuilder.buildLinkFromTableItemType(this.row());
        } else if (selectedOption === ContextMenuOptions.PREVIEW) {
            this.logger.debug('Opening modal with dehn plan file preview image');
            this.blurOverlayService.toggleOverlayState();
            this.modalState.set(true);
        }
    }

    /**
     * @description - Closes the modal and removes the blur overlay
     */
    handleCloseModal() {
        this.blurOverlayService.toggleOverlayState();
        this.modalState.set(false);
    }
}
