import { Languages } from 'src/app/shared/constants';

export interface Notification {
    id: string;
    title: string;
    content: string;
    locale: Languages;
    importance: NotificationImportance;
    openInNewTab: boolean;
    _ts: string;
}

export enum NotificationImportance {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    NORMAL = 'NORMAL'
}

export interface NotificationData {
    items: Notification[];
    loaded: boolean;
}
