import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    private http = inject(HttpClient);

    /**
     * @param dataURI - The base64 string value.
     * @returns - An Observable<string> of the blob URL of the image.
     */
    dataURIToBlob(dataURI: string): Observable<string> {
        return of(dataURI).pipe(
            map((dataURI) => (dataURI.includes('data:image/jpeg;base64,') ? dataURI : `data:image/jpeg;base64,${dataURI}`)),
            switchMap((uri) => this.http.get(uri, { responseType: 'arraybuffer' })),
            map((arrayBuffer) => {
                const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
                return URL.createObjectURL(blob);
            }),
            catchError(() => of(''))
        );
    }
}
