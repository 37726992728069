<nav id="main-nav" class="z-20 fixed top-0 right-0 flex justify-end h-screen">
    <section id="main-nav-lists" class="h-full p-8 bg-primary-realwhite">
        @if (!isSubMenuOpen()) {
            <app-button type="primary" icon="cancel" iconSize="tiny" (click)="toggleHamburgerMenu()">{{
                'common.button.close' | translate
            }}</app-button>
        }

        @for (sectionName of sectionsNames; track sectionName) {
            <app-nav-item [sections]="NavSections.sections[sectionName]"></app-nav-item>
        }
    </section>

    @if (isSubMenuOpen()) {
        <section id="second-nav" class="flex flex-col h-full p-8 bg-primary-offwhite">
            <app-button type="primary" icon="cancel" iconSize="tiny" (click)="toggleHamburgerMenu()">
                {{ 'common.button.close' | translate }}
            </app-button>

            <app-nav-item [sections]="NavSections.sections[subMenuName()]"></app-nav-item>
        </section>
    }
</nav>
