import { Component } from '@angular/core';
import { SearchTableService } from 'src/app/shared/services';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
    standalone: true,
    imports: [SvgIconComponent, CommonModule, FormsModule, TranslateModule],
    selector: 'app-searchbox',
    templateUrl: './searchbox.component.html'
})
export class SearchboxComponent {
    searchQuery = '';

    constructor(private searchTableService: SearchTableService) {}

    /**
     * @description Sets the search query to the search table service
     */
    onSearch() {
        this.searchTableService.setSearchQuery = this.searchQuery;
    }

    /**
     * @description Clears the search query and sets the search table service
     */
    onClear() {
        this.searchQuery = '';
        this.onSearch();
    }
}
