<ul class="mx-0 my-12 list-none p-0">
    @for (item of sectionsArray(); track item.key) {
        <li>
            <a
                class="flex min-w-[14rem] items-center justify-between border-b border-solid py-2 no-underline hover:cursor-pointer hover:border-b-primary-red hover:text-primary-red"
                [ngClass]="itemConditionalClasses(item)"
                (click)="handleClick(item)"
                [attr.href]="!item?.subMenuName ? getUrl(item) : null"
            >
                <div class="flex items-center gap-x-3">
                    @if (item.iconName; as iconName) {
                        <app-svg-icon [icon]="iconName" size="medium" variant="default" direction="none"></app-svg-icon>
                    }
                    <span>{{ 'header.menu.' + item.key | translate }} </span>
                </div>

                @if (item?.subMenuName) {
                    <app-svg-icon icon="arrow" size="tiny" direction="right" variant="default"></app-svg-icon>
                }
            </a>
        </li>
    }
</ul>
