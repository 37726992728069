<section class="flex flex-row gap-5">
    <div id="pages-container" class="flex flex-row items-center gap-1">
        <!--Previous page button-->
        <app-button
            type="secondary"
            icon="arrow"
            iconSize="tiny"
            iconDirection="left"
            size="small"
            [knob]="true"
            [disabled]="paginationData().currentPageData.page === 1"
            (click)="handlePageChange(paginationData().currentPageData.page - 1)"
        ></app-button>

        <!--Pages buttons-->

        @for (page of paginationData().visiblePages; track page) {
            @if (page) {
                <app-button
                    type="secondary"
                    size="small"
                    [solid]="page === paginationData().currentPageData.page"
                    (click)="handlePageChange(page)"
                >
                    {{ page }}
                </app-button>
            } @else {
                <app-button type="secondary" size="small" [knob]="true" icon="ellipsis" iconSize="tiny"></app-button>
            }
        }

        <!--Next page button-->

        <app-button
            type="secondary"
            size="small"
            [knob]="true"
            icon="arrow"
            iconDirection="right"
            iconSize="tiny"
            [disabled]="paginationData().currentPageData.page === paginationData().totalPages"
            (click)="handlePageChange(paginationData().currentPageData.page + 1)"
        ></app-button>
    </div>

    <!--Items per page dropdown-->
    <app-dropdown-button [options]="pageSizesArray" (selectedOptionResponse)="handleOptionSelected($event)"></app-dropdown-button>
</section>
