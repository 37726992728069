import { Component } from '@angular/core';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [LanguageSelectorComponent, TranslateModule],
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {}
