<!--Global flex container-->
<div id="main-content-container" class="flex min-h-screen flex-col gap-y-9">
    <!--App header-->
    <app-header></app-header>

    <!--Breadcrumb-->
    <ol class="mx-auto w-90 max-w-screen-xl text-xs text-dimmed-white">
        <div>
            <a class="text-primary-realwhite">{{ 'section.dashboard.title' | translate }}</a>
        </div>
    </ol>

    <!--Main content-->
    <main id="main-app-container" class="mx-auto w-90 max-w-7xl">
        <app-dashboard></app-dashboard>
    </main>

    <!--Footer-->
    <app-footer class="mt-auto"></app-footer>
</div>

<!--Overlay-->
@if (isOverlayTriggered()) {
    <app-blur-overlay></app-blur-overlay>
}
