import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, skipWhile } from 'rxjs';
import { User } from '../../shared/interfaces';
import { LanguageLegacyService, UserService } from '../services';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private languageOptions: string[];
    private browserLanguage: string;
    private languageByParam = false;

    constructor(
        private readonly translateService: TranslateService,
        private readonly userService: UserService,
        private readonly languageLegacyService: LanguageLegacyService
    ) {
        this.languageOptions = this.translateService.getLangs();
        this.browserLanguage = this.getLanguageFromCode(this.translateService.getBrowserLang() ?? '');
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const paramLanguage = route.queryParams['language'] ? this.getLanguageFromCode(route.queryParams['language']) : '';
        toObservable(this.userService.userData)
            .pipe(
                skipWhile((user: User) => !Object.keys(user).length),
                map((user: User) => (user?.language ? this.getLanguageFromCode(user.language) : ''))
            )
            .subscribe((userLanguage: string) => {
                this.setLanguage(this.getLanguage(paramLanguage, userLanguage));
            });

        return true;
    }

    /**
     * @description - Default language is english, if user language is not supported and param language is missing
     * @param paramLanguage - The language from the url param
     * @param userLanguage - The language from the user data
     * @returns - The selected language to set to the translate service and use in the app
     */
    getLanguage(paramLanguage: string, userLanguage: string): string {
        let selectedLanguage: string = this.translateService.getDefaultLang();

        if (this.languageOptions.includes(paramLanguage)) {
            selectedLanguage = paramLanguage;
            this.languageByParam = true;
        } else if (this.languageOptions.includes(userLanguage)) {
            selectedLanguage = userLanguage;
        } else if (this.languageOptions.includes(this.browserLanguage)) {
            selectedLanguage = this.browserLanguage;
        }

        return selectedLanguage;
    }

    /**
     * @param selectedLanguage - The final language to set to the translate service and use in the app
     */
    private setLanguage(selectedLanguage: string) {
        this.translateService.use(selectedLanguage);
        if (this.languageByParam) this.languageLegacyService.setLegacyLanguageByISOCode(selectedLanguage);
    }

    /**
     * @param code - The language code
     * @returns - The language string after formatting the code
     */
    private getLanguageFromCode(code: string): string {
        return this.languageOptions.find((language: string) => language.includes(code.toLocaleLowerCase())) ?? '';
    }
}

/**
 * @param route The route snapshot
 * @returns A boolean indicating if the user can activate the route
 */
export const languageGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(LanguageService).canActivate(route);
};
