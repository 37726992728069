import { Component, EventEmitter, Output, TemplateRef, ViewChild, input } from '@angular/core';
import { DropdownPanel } from '../../directives/triggerDopMenu/dropdown-panel.interface';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [TranslateModule],
    selector: 'app-drop-menu',
    templateUrl: './drop-menu.component.html'
})
export class DropMenuComponent implements DropdownPanel {
    @ViewChild(TemplateRef) templateRef!: TemplateRef<unknown>;

    options = input.required<string[]>({
        alias: 'menuOptions'
    });
    @Output() closed = new EventEmitter<void>();
    @Output() selectedOption = new EventEmitter<string>();

    /**
     * @param option - The option selected to emit from the drop menu
     */
    handleSelectedOption(option: string) {
        this.selectedOption.emit(option);
        this.closed.emit();
    }
}
