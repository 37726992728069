<header class="slantdown slantdown-red bg-primary-red p-0 text-primary-realwhite">
    <!--Flex container for all header items-->
    <div class="mx-auto flex w-90 max-w-7xl justify-between py-9">
        <div
            class="flex flex-col space-y-7 md:w-7/12 md:flex-row md:items-center md:justify-between md:space-y-0 lg:w-7/12 lg:flex-row lg:items-center lg:justify-between lg:space-y-0"
        >
            <!--Company Logo-->
            <app-image
                type="assets"
                src="DEHN-logo-white.png"
                altText="DEHN-LOGO"
                [width]="190"
                [height]="50"
                customStyles="w-28 md:w-48 lg:w-48"
            ></app-image>

            <!--Project name-->
            <h2 id="project-name" class="text-base md:mr-20 lg:mr-20">
                {{ 'header.title' | translate }}
            </h2>
        </div>

        <!--Header nav buttons-->
        <div
            id="header-buttons"
            class="flex space-x-2 md:items-center md:space-x-5 lg:items-center lg:space-x-7"
            appClickOutside
            (clickOutside)="clickedOutside()"
        >
            <div class="relative inline-block" [ngClass]="{ 'z-50': isUserCardOpen() }">
                <!--User info card button-->
                <app-svg-icon
                    icon="user"
                    size="medium"
                    [variant]="isUserIconHovered() ? 'solid' : 'default'"
                    direction="none"
                    class="pr-3 pt-2 hover:cursor-pointer"
                    (mouseleave)="handleMouseLeave()"
                    (mouseenter)="handleMouseEnter()"
                    (click)="toggleUserInfoCard()"
                ></app-svg-icon>

                <!--User info card-->
                @if (isUserCardOpen()) {
                    <app-user-card></app-user-card>
                }
            </div>

            <!--Hamburger menu open button-->
            <app-button
                type="primary"
                [knob]="isSmallScreen()"
                [size]="isSmallScreen() ? 'small' : 'normal'"
                [solid]="true"
                icon="burger"
                iconSize="tiny"
                (click)="toggleHamburgerMenu()"
            >
                {{ 'common.button.menu' | translate }}
            </app-button>

            <!--Hamburger menu-->
            @if (isHamburgerMenuOpen()) {
                <app-hamburger-menu></app-hamburger-menu>
            }
        </div>
    </div>
</header>
