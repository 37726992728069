<div
    class="absolute -left-28 top-full z-50 max-h-96 min-w-[17rem] translate-y-3 overflow-hidden bg-primary-realwhite p-4 text-secondary-text shadow-lg"
>
    <div class="flex justify-end">
        <app-button type="secondary" size="small" [knob]="true" icon="cancel" iconSize="tiny" (click)="closeCard()"></app-button>
    </div>
    <div class="flex flex-col items-center space-y-5">
        <app-svg-icon icon="user" size="large" variant="avatar" direction="none"></app-svg-icon>
        <p class="text-small">{{ 'header.userCard.loggedIn' | translate }}</p>

        <div id="info-container" class="flex flex-col items-center gap-y-2">
            <h5 class="hover:cursor-default">{{ userData().firstName }} {{ userData().lastName }}</h5>
            <p class="hover:cursor-default">{{ userData().eMail }}</p>
        </div>

        <app-button type="secondary" size="small" (click)="logout()">{{ 'header.userCard.signOut' | translate }}</app-button>
    </div>
</div>
