<footer class="px-0 py-8 mx-0 mt-32 mb-0 bg-secondary-gainsboro slantup slantup-gainsboro">
    <section class="flex justify-between max-w-screen-xl m-auto w-90">
        <nav id="footer-navbar">
            <ul class="flex flex-col gap-2 md:flex-row md:gap-6 lg:flex-row lg:gap-8 p-0 list-none">
                <li class="hover:cursor-pointer">
                    <a href="https://www.dehn.de/de/kontakt" target="_blank">{{ 'footer.contact' | translate }}</a>
                </li>
                <li class="hover:cursor-pointer">
                    <a href="https://www.dehn.de/de/impressum" target="_blank">{{ 'footer.legal' | translate }}</a>
                </li>
                <li class="hover:cursor-pointer">
                    <a href="https://www.dehn.de/de/datenschutzerklaerung" target="_blank">{{ 'footer.privacy' | translate }}</a>
                </li>
            </ul>
        </nav>

        <app-language-selector></app-language-selector>

        <p class="legal-note">{{ 'footer.companyName' | translate }}</p>
    </section>
</footer>
