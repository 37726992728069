import { HttpHeaders, HttpParams } from '@angular/common/http';

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

export interface RequestOptions {
    method: HttpMethod;
    url: string;
    params?: HttpParams;
    headers?: HttpHeaders | { [header: string]: string | string[] };
    body?: unknown;
}
