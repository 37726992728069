export class UriUtils {
    /**
     * @param host URL host
     * @param parts URL parts to append to host
     * @returns URL built from host and parts
     * TODO: Improve this method
     */
    static appendParts(host: string, ...parts: string[]): string {
        let builtUrl = host;
        if (builtUrl.endsWith('/')) {
            builtUrl = builtUrl.slice(0, -1);
        }

        parts.forEach((part: string) => {
            if (!part.startsWith('/')) {
                part = '/' + part;
            }

            builtUrl += part;
        });

        return builtUrl;
    }
}
