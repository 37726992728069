import { HttpParams } from '@angular/common/http';
import { TableFilters } from '../../interfaces';

export class HttpUtils {
    /**
     * @param filterOptions The filter options object with filter info
     * @returns The HttpParams object with the filter options object info spread it and stringified
     */
    static createTableFilterHttpParams(filterOptions: TableFilters): HttpParams {
        const combinedOptions = Object.keys(filterOptions).reduce((combined, key) => {
            return {
                ...combined,
                ...filterOptions[key]
            };
        }, {});

        return new HttpParams().set('filterOptions', JSON.stringify(combinedOptions));
    }
}
