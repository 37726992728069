interface TIndexer<T> {
    [key: string]: T;
}

export class StringUtils {
    /**
     * @param timestamp The timestamp to convert to a date
     * @returns The date in string version converted from the timestamp
     */
    static timeStampToDate(timestamp: string, customIntlFormat?: string): string {
        const timeStampToDate: Date = new Date(+timestamp * 1000);
        return customIntlFormat ? timeStampToDate.toLocaleString(customIntlFormat) : timeStampToDate.toLocaleString();
    }

    /**
     * @param str The string to extract the substring from
     * @param start The start index of the substring
     * @param end The end index of the substring
     * @param finishWithDots Whether to finish the substring with dots or not
     * @returns The substring extracted from the string either finished with dots or not
     */
    static extractSubstring(str: string, start: number, end: number, finishWithDots: boolean): string {
        return str.substring(start, end) + (finishWithDots ? '...' : '');
    }

    /**
     * @param data The data to filter by the search query
     * @param searchQuery The search query to filter the data by
     * @returns The filtered data by the search query
     */
    static filterBySearchQuery<T>(data: T[], searchQuery: string): T[] {
        if (!searchQuery) {
            return data;
        } else {
            const lowercasedSearchQuery: string = searchQuery.toLowerCase();

            return data.filter((item: T) => {
                return Object.keys(item as TIndexer<T>).some((key: string) => {
                    return (item as TIndexer<T>)[key]?.toString().toLowerCase().includes(lowercasedSearchQuery);
                });
            });
        }
    }
}
