import { Component, computed, inject } from '@angular/core';
import { PaginationService } from '../../../services';
import { ButtonComponent, DropdownButtonComponent } from '../../';
import { DropDownSelection } from '../../dropdown-button';
import { PageSize } from 'src/app/shared/services/pagination';

@Component({
    standalone: true,
    imports: [ButtonComponent, DropdownButtonComponent],
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent implements DropDownSelection<PageSize> {
    pageSizesArray: PageSize[] = [5, 10, 25];

    paginationData = computed(() => {
        return this.paginationService.paginationData();
    });

    paginationService = inject(PaginationService);

    /**
     * @param option - Handle the option selected event.
     */
    handleOptionSelected(option: PageSize): void {
        this.paginationService.handleItemsPerPageChange(option);
    }

    /**
     * @param page - Handle the page change event.
     */
    handlePageChange(page: number): void {
        this.paginationService.goToPage(page);
    }
}
