import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, catchError, of, throwError } from 'rxjs';
import { RequestOptions } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private destroyRef$ = inject(DestroyRef);

    constructor(protected httpClient: HttpClient) {}

    /**
     * @param options HTTP request options object containing the request information
     * @param serviceName Name of the service that is requesting the operation
     * @returns An observable of the type T
     */
    request<T>(options: RequestOptions, serviceName: string): Observable<T> {
        return this.httpClient
            .request<T>(options.method, options.url, {
                params: options.params,
                headers: options.headers,
                body: options.body,
                observe: 'body'
            })
            .pipe(
                takeUntilDestroyed(this.destroyRef$),
                catchError((error: HttpErrorResponse) => {
                    return this.handleError<T>(error, serviceName, options.method);
                })
            );
    }

    /**
     * @param error - The error object containing the error information
     * @param serviceName - The name of the service that is requesting the operation
     * @param operation - The operation executed by the service when the error ocurred
     * @returns - An empty observable of the type T or throws an error
     */
    private handleError<T>(error: unknown, serviceName: string, operation = 'operation'): Observable<T> {
        if (error instanceof HttpErrorResponse) {
            //TODO: Add logger service to log the error
            return of([] as T);
        } else {
            return throwError(() => new Error(`An error ocurred trying to do ${operation} operation in ${serviceName} service`));
        }
    }
}
