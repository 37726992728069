import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
    @Output() clickOutside = new EventEmitter<void>();

    documentClickSubscription$!: Subscription;
    constructor(
        private elementRef: ElementRef,
        @Inject(DOCUMENT) private document: Document
    ) {}

    /**
     * @description - Subscribe to the document click event and emit the clickOutside event if the click was outside the directive's element.
     */
    ngAfterViewInit(): void {
        this.documentClickSubscription$ = fromEvent(this.document, 'click')
            .pipe(
                filter((event: Event) => {
                    return !this.isInside(event.target as HTMLElement);
                })
            )
            .subscribe(() => {
                this.clickOutside.emit();
            });
    }

    /**
     * @description - Unsubscribe from the document click event when the directive is destroyed.
     */
    ngOnDestroy(): void {
        this.documentClickSubscription$.unsubscribe();
    }

    /**
     * @param targetElement - The element that in the DOM that was clicked on.
     * @returns - Whether or not the target element is inside the directive's element.
     */
    isInside(targetElement: HTMLElement): boolean {
        return targetElement === this.elementRef.nativeElement || this.elementRef.nativeElement.contains(targetElement);
    }
}
