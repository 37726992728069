import packageInfo from '../../package.json';

export const environment = {
    packageInfo: packageInfo,
    production: false,
    serverUrl: 'https://dev.svc.dashboard-dehn-project.dds-cloud.net',
    keycloakUrl: 'https://dehn-keycloak-test.azurefd.net/auth',
    logoutPath: 'https://dev.ui.dashboard-dehn-project.dds-cloud.net',
    keycloakRealm: 'DEHNProject',
    keycloakClient: 'dehn-project',
    legacyDEHNProjectUrl: 'https://dev2-dehnproject.dds-cloud.net',
    legacyDEHNProjectApiUrl: 'https://api-dev2-dehnproject.dds-cloud.net/api',
    systemname: 'dev',
    buildId: '0',
    datadog: {
        sampleRate: 0,
        premiumSampleRate: 0
    }
};
