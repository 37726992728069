<div class="flex items-center">
    <hr class="w-6 rotate-90 border" />
    <app-svg-icon icon="ellipsis" size="medium" variant="default" direction="none" [appDropdownTriggerFor]="dropMenu"></app-svg-icon>
</div>

<!-- Context menu -->
<app-drop-menu #dropMenu [menuOptions]="contextMenuOptions" (selectedOption)="handleSelectedOption($event)"></app-drop-menu>

<!--Modal-->
@if (modalState()) {
    <app-modal [title]="row().name" (closeModal)="handleCloseModal()">
        @if (row().previewImage) {
            <app-image type="base64" [src]="row().previewImage!" customStyles="flex justify-center min-w-[200px]"></app-image>
        }
    </app-modal>
}
