import { map, Observable, Subscription, tap } from 'rxjs';
import { ApiService, PaginationService, SortingTableService } from 'src/app/shared/services';
import { HttpUtils, StringUtils, UriUtils } from 'src/app/shared/utils';

import { HttpParams } from '@angular/common/http';
import { effect, EffectCleanupRegisterFn, EffectRef, inject, Injectable, signal, Signal } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { DataResponse, HttpMethod, RequestOptions, SortingInfo, TableFilters } from '../../../shared/interfaces';
import { History } from '../../interfaces/history.interface';
import { PageInfo } from 'src/app/shared/services/pagination';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    private sortingTableService = inject(SortingTableService);
    private paginationService = inject(PaginationService);
    private apiService = inject(ApiService);

    private historyDataSignal = signal<DataResponse<History>>({
        totalCount: 0,
        items: [],
        loaded: false
    });

    /**
     * @description - The effect that listens for changes in the current page info or sorting info signals and runs the getLatestChanges method to fetch the latest history data.
     */
    dataEffect: EffectRef = effect((onCleanup: EffectCleanupRegisterFn) => {
        const currentPageInfoSignal: Signal<PageInfo> = this.paginationService.currentPageInfo;
        const sortingInfoSignal: Signal<SortingInfo> = this.sortingTableService.sortingInfo;

        const historyData$ = this.getLatestChanges(currentPageInfoSignal(), sortingInfoSignal()).pipe(
            tap((historyData: DataResponse<History>) => {
                this.historyDataSignal.set(historyData);
            })
        );

        const historyDataSub: Subscription = historyData$.subscribe();

        onCleanup(() => historyDataSub.unsubscribe());
    });

    //* PUBLIC (API) SIGNAL *//
    historyData: Signal<DataResponse<History>> = this.historyDataSignal.asReadonly();

    /**
     * @returns - An Observable of the latest history data
     */
    private getLatestChanges(currentPageInfo: PageInfo, sortingInfo: SortingInfo): Observable<DataResponse<History>> {
        const requestOptions: RequestOptions = {
            method: HttpMethod.GET,
            url: this.buildUrl(),

            params: this.buildQueryParams({
                paginationInfo: {
                    page: currentPageInfo.page,
                    size: currentPageInfo.size
                },
                sortingInfo: {
                    sort: sortingInfo.sort,
                    order: sortingInfo.order
                }
            })
        };

        return this.apiService.request<DataResponse<History>>(requestOptions, `getLatestChanges`).pipe(
            map((historyData: DataResponse<History>) => {
                this.paginationService.setTotalItems(historyData.totalCount);

                const formattedHistory = historyData.totalCount
                    ? historyData.items.map((item: History) => {
                          return {
                              ...item,
                              name: item.name.length > 20 ? StringUtils.extractSubstring(item.name, 0, 20, true) : item.name,
                              lastUpdated: StringUtils.timeStampToDate(item.lastUpdated)
                          };
                      })
                    : [];

                return {
                    totalCount: historyData.totalCount,
                    items: formattedHistory,
                    loaded: true
                };
            })
        );
    }

    /**
     * @param segments - URL segments to append to the environment server URL
     * @returns - The URL built from the environment server URL and the segments
     */
    private buildUrl(...segments: string[]): string {
        return UriUtils.appendParts(environment.serverUrl, '/v1/history', ...segments);
    }

    /**
     * @param filterOptions - The filter options object with filter info to convert to HttpParams
     * @returns - The HttpParams object with the filter info stringified
     */
    private buildQueryParams(filterOptions: TableFilters): HttpParams {
        return HttpUtils.createTableFilterHttpParams(filterOptions);
    }
}
