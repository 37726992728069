<div class="z-[150] fixed w-full h-full top-0 left-0 flex flex-col items-center justify-center">
    <div
        [@modalEnterAnimation]
        id="current-modal-instance"
        class="px-8 overflow-y-auto py-7 bg-primary-realwhite max-w-[80%] max-h-[80%] min-w-[25%]"
    >
        <div class="flex flex-col space-y-5">
            <!--Modal heading section container-->
            <section class="flex justify-between">
                <div class="flex flex-col space-y-2">
                    <!--Modal title-->
                    @if (title()) {
                        <h4 id="message-title" class="m-0 text-xl">{{ title() }}</h4>
                    }

                    <!--Modal subtitle-->
                    @if (subtitle()) {
                        <p class="m-0 text-xs">{{ subtitle() }}</p>
                    }
                </div>

                <!--Actions button container-->
                <div class="flex space-x-2">
                    <app-button type="secondary" [knob]="true" size="small" icon="cancel" iconSize="tiny" (click)="stopModal()">
                    </app-button>
                </div>
            </section>

            <!--Modal body section container-->
            <section class="flex flex-col">
                @if (content()) {
                    <p class="m-0">{{ content() }}</p>
                } @else {
                    <ng-content></ng-content>
                }
            </section>
        </div>
    </div>
</div>
