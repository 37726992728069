<div class="flex items-center space-x-2" (mouseenter)="onHeaderMouseEnter()" (mouseleave)="onHeaderMouseLeave()">
    <span>{{ 'common.table.header.' + header().key | translate }}</span>
    @if ((isHeaderHovered() || isHeaderSorted) && header().isSortable) {
        <app-svg-icon
            icon="arrow"
            size="tiny"
            variant="default"
            [direction]="sortingState().order === 'asc' ? 'up' : 'down'"
            [color]="isHeaderHovered() && !isHeaderSorted ? arrowIconColor : ''"
            class="cursor-pointer"
            (click)="handleSwitchSortingInfo()"
        >
        </app-svg-icon>
    }
</div>
