<button type="button" [ngClass]="classes" [disabled]="disabled">
    <div class="flex items-center" [ngClass]="icon && iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse'">
        @if (icon) {
            <app-svg-icon
                [icon]="icon"
                [size]="iconSize"
                [variant]="iconVariant"
                [direction]="iconDirection"
                [color]="iconColor"
            ></app-svg-icon>
        }
        @if (!knob) {
            <span class="text-current" [ngClass]="{ 'mr-2': icon && iconPosition === 'right', 'ml-2': icon && iconPosition === 'left' }">
                <ng-content></ng-content>
            </span>
        }
    </div>
</button>
