import { Injectable, Signal, inject, signal } from '@angular/core';
import { OverlayService } from '../../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class HamburgerMenuService {
    private isHamburgerMenuOpenSignal = signal<boolean>(false);
    private isSubMenuOpenSignal = signal<boolean>(false);
    private selectedSubMenuSignal = signal<string>('');

    private overlayService = inject(OverlayService);

    get hamburgerMenuStateValue(): Signal<boolean> {
        return this.isHamburgerMenuOpenSignal.asReadonly();
    }

    get subMenuStateValue(): Signal<boolean> {
        return this.isSubMenuOpenSignal.asReadonly();
    }

    get selectedSubMenuValue(): Signal<string> {
        return this.selectedSubMenuSignal.asReadonly();
    }

    /**
     *@description - Toggle the hamburger menu state.
     */
    toggle(): void {
        this.overlayService.toggleOverlayState();
        this.isHamburgerMenuOpenSignal.set(!this.isHamburgerMenuOpenSignal());
    }

    /**
     *@description - Open the sub menu.
     */
    openSubMenu(): void {
        this.isSubMenuOpenSignal.set(true);
    }

    /**
     *@description - Close the sub menu.
     */
    closeSubMenu(): void {
        this.isSubMenuOpenSignal.set(false);
        this.selectedSubMenuSignal.set('');
    }

    /**
     * @description - Toggles the sub menu state.
     */
    toggleSubMenu(): void {
        this.isSubMenuOpenSignal.set(!this.isSubMenuOpenSignal());
    }

    /**
     * @param subMenuName The name of the sub menu to switch to
     */
    switchSubMenu(newName: string): void {
        this.selectedSubMenuSignal.update((currentName) => {
            if (currentName === newName) {
                this.closeSubMenu();
                return '';
            } else {
                this.openSubMenu();
                return newName;
            }
        });
    }
}
