import { Injectable, Signal, signal } from '@angular/core';
import { SortOrder, SortingInfo } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class SortingTableService {
    private sortingInfoSignal = signal<SortingInfo>({ sort: '_ts', order: SortOrder.DESC });

    //* PUBLIC (API) SIGNAL *//
    sortingInfo: Signal<SortingInfo> = this.sortingInfoSignal.asReadonly();

    /**
     * @param selectedHeader - The selected header to sort by
     */
    switchSortingInfo(selectedHeader: string): void {
        this.sortingInfoSignal.update((currentSortingInfo) => {
            const newOrder = currentSortingInfo.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
            return {
                sort: selectedHeader,
                order: newOrder
            };
        });
    }
}
