import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Languages } from './shared/constants';
import { SiteLayoutComponent } from './layout/components';

@Component({
    standalone: true,
    imports: [SiteLayoutComponent],
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(private readonly translateService: TranslateService) {
        const languagesAvailable = Object.values(Languages);
        this.translateService.addLangs(languagesAvailable);
        this.translateService.setDefaultLang(Languages.ENGLISH);
    }
}
