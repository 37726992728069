import { environment } from 'src/environments/environment';
import { TableData } from '../../interfaces';
import { HistoryType } from 'src/app/dashboard/interfaces';

export class LinkBuilder {
    private static dehnProjectLegacyUrl = `${environment.legacyDEHNProjectUrl}/`;

    /**
     * @description - Redirects to the legacy project according to the passed table cell data type
     * @param - tableCell: The table cell data to build the link from
     */
    static buildLinkFromTableItemType(tableCell: TableData): void {
        let redirectUrl = '';

        const itemKey =
            tableCell.sortKey === HistoryType.Building || tableCell.sortKey === HistoryType.File ? 'dpobject' : tableCell.sortKey;

        if (tableCell.sortKey === HistoryType.BOM) {
            redirectUrl = `${LinkBuilder.dehnProjectLegacyUrl}bom/list/${tableCell.planId}`;
        } else if (tableCell.sortKey === HistoryType.File) {
            redirectUrl = `${LinkBuilder.dehnProjectLegacyUrl}${itemKey}/view/${tableCell.buildingId}/files`;
        } else {
            redirectUrl = `${LinkBuilder.dehnProjectLegacyUrl}${itemKey}/view/${tableCell.id}`;
        }

        LinkBuilder.openUrl(redirectUrl);
    }

    /**
     * @description - Redirects to the legacy project with the given menu key
     * @param - menuKey: The menu key to redirect to the legacy project
     */
    static redirectToLegacyProject(menuKey: string): string {
        return `${LinkBuilder.dehnProjectLegacyUrl}/${menuKey}`;
    }

    /**
     * @description - Opens the given url in the current tab
     * @param - url: The url to open
     */
    private static openUrl(url: string): void {
        window.open(url, '_self');
    }
}
