import { Component, computed, input } from '@angular/core';
import { IconDirection, IconStyle, SvgIconTypes, ICONS_PATH, iconMeasures, IconSize } from './';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-svg-icon',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './svg-icon.component.html'
})
export class SvgIconComponent {
    size = input.required<IconSize>();
    iconName = input.required<SvgIconTypes>({
        alias: 'icon'
    });
    variant = input.required<IconStyle>();
    direction = input.required<IconDirection>();
    color = input<string>('currentColor');

    iconMeasures = computed(() => iconMeasures[this.size()]);

    iconDirection = computed(() => (this.direction() !== 'none' ? `-${this.direction()}` : ''));

    iconVariant = computed(() => (this.variant() !== 'default' ? `-${this.variant()}` : ''));

    /**
     * @description - Build the icon structure according the props to be used in the template as an SVG.
     */
    iconStructure = computed(() => {
        return `${ICONS_PATH}#${this.iconName()}${this.iconDirection()}${this.iconVariant()}-${this.iconMeasures()}`;
    });
}
