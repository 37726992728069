import { OverlayService } from 'src/app/shared/services';

import { Injectable, Signal, inject, signal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserCardService {
    private userCardStateSignal = signal<boolean>(false);

    overlayService = inject(OverlayService);

    /**
     * @description - Expose the user card state as a readonly signal.
     * @returns - A Signal<boolean> with the user-card state value as a readonly.
     */
    get userCardStateValue(): Signal<boolean> {
        return this.userCardStateSignal.asReadonly();
    }

    /**
     * @description - Toggle the user card state.
     */
    toggleUserCard(): void {
        this.userCardStateSignal.set(!this.userCardStateSignal());

        this.overlayService.toggleOverlayState();
    }

    /**
     * @description - Close the user card.
     */
    closeUserCard(): void {
        this.overlayService.toggleOverlayState();

        this.userCardStateSignal.set(false);
    }
}
