import { Observable, map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable, Signal, inject } from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Notification, NotificationData } from '../../../dashboard/interfaces';
import { HttpMethod, RequestOptions } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { StringUtils, UriUtils } from '../../../shared/utils';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private apiService = inject(ApiService);
    private translateService = inject(TranslateService);

    /**
     * @description - Convert the observable of the notifications data to a signal.
     */
    private notificationDataSignal: Signal<NotificationData> = toSignal<NotificationData, NotificationData>(this.handleLanguageChange(), {
        initialValue: {
            items: [],
            loaded: false
        }
    });

    //* PUBLIC (API) SIGNAL *//
    notificationsData: Signal<NotificationData> = this.notificationDataSignal;

    /**
     * @returns - An observable of the notifications data when the language changes.
     */
    public handleLanguageChange(): Observable<NotificationData> {
        return this.translateService.onLangChange.pipe(
            mergeMap((event: LangChangeEvent) => {
                return this.getAllNotifications(event.lang.split('-')[0].toUpperCase());
            })
        );
    }

    /**
     * @returns - An observable of the notifications data.
     */
    private getAllNotifications(language: string): Observable<NotificationData> {
        const requestOptions: RequestOptions = {
            method: HttpMethod.GET,
            url: this.buildUrl('published'),
            params: new HttpParams().set('locale', language)
        };
        return this.apiService.request<Notification[]>(requestOptions, `getAllNotifications`).pipe(
            map((notifications: Notification[]) =>
                notifications.map((notification: Notification) => ({
                    ...notification,
                    _ts: StringUtils.timeStampToDate(notification._ts)
                }))
            ),
            map((notifications) => ({
                items: notifications,
                loaded: true
            }))
        );
    }

    /**
     * @param notificationId - The id of the notification to close
     * @returns - An observable with the id of the closed notification
     */
    closeNotification(notificationId: string): Observable<string> {
        const requestOptions: RequestOptions = {
            method: HttpMethod.POST,
            url: this.buildUrl(`close/${notificationId}`)
        };

        return this.apiService.request<string>(requestOptions, `removeNotification`);
    }

    /**
     * @param segments - URL segments to append to the environment server URL
     * @returns - The URL built from the environment server URL and the segments
     */
    private buildUrl(...segments: string[]): string {
        return UriUtils.appendParts(environment.serverUrl, '/v1/notification', ...segments);
    }
}
