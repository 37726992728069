import { Component, EventEmitter, Output, input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ButtonComponent } from '../button/button.component';

const modalEnterAnimation = trigger('modalEnterAnimation', [
    state('void', style({ transform: 'scale(0.7)', opacity: 0 })),
    state('*', style({ transform: 'scale(1)', opacity: 1 })),
    transition('void => *', animate('300ms ease-out')),
    transition('* => void', animate('200ms ease-in'))
]);
@Component({
    standalone: true,
    imports: [ButtonComponent],
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    animations: [modalEnterAnimation]
})
export class ModalComponent {
    title = input<string>('');
    subtitle = input<string>('');
    content = input<string>('');

    @Output() closeModal = new EventEmitter<boolean>(false);

    /**
     * @description Close the modal and emit the event to the parent component
     */
    stopModal() {
        this.closeModal.emit();
    }
}
