import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { LoggerService } from '../logger/logger.service';
import { catchError, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';

declare global {
    interface Window {
        _DATADOG_SYNTHETICS_BROWSER: unknown;
    }
}

@Injectable({
    providedIn: 'root'
})
export class DatadogService {
    private readonly logger: LoggerService;

    private readonly CLIENT_TOKEN = 'pub91f890a2da1ca0b95a330dbaa227032f';
    private readonly SITE = 'datadoghq.eu';
    private readonly SERVICE = 'dehnproject-dashboard-ui';

    constructor(private readonly keycloakService: KeycloakService) {
        this.logger = this.createLogger('datadog-service');
    }

    public init() {
        datadogLogs.init({
            clientToken: this.CLIENT_TOKEN,
            site: this.SITE,
            forwardErrorsToLogs: true,
            sampleRate: 100,
            service: this.SERVICE,
            version: environment.packageInfo.version + '.' + environment.buildId,
            env: environment.systemname
        });

        datadogRum.init({
            applicationId: '2f6076e1-258f-45eb-9f14-59e7ab689481',
            clientToken: this.CLIENT_TOKEN,
            site: this.SITE,
            service: this.SERVICE,
            version: environment.packageInfo.version + '.' + environment.buildId,
            env: environment.systemname,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            sampleRate: environment.datadog.sampleRate,
            premiumSampleRate: environment.datadog.premiumSampleRate,
            trackInteractions: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'mask-user-input'
        });

        this.setUser();

        datadogRum.startSessionReplayRecording();
    }

    public createLogger(context: string) {
        return new LoggerService(datadogLogs.createLogger(context), context);
    }

    public addAction(name: string, context?: object) {
        datadogRum.addAction(name, context);
    }

    public isDatadogSyntheticBrowser() {
        return window._DATADOG_SYNTHETICS_BROWSER;
    }

    private setUser() {
        from(this.keycloakService.loadUserProfile(true))
            .pipe(
                catchError((error) => {
                    this.logger.error('Error loading Keycloak User Profile', error);
                    return of({ email: '' });
                })
            )
            .subscribe((profile) => {
                try {
                    const user = {
                        id: this.keycloakService?.getKeycloakInstance()?.subject,
                        email: profile.email
                    };

                    datadogLogs.setLoggerGlobalContext({ user });
                    datadogRum.setUser(user);
                } catch (error) {
                    this.logger.error('Error setting user');
                }
            });
    }
}
