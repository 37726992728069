export const ICONS_PATH = 'assets/icons/icons.svg' as const;

export type IconStyle = 'default' | 'solid' | 'avatar' | 'regular' | 'light';

export type IconDirection = 'up' | 'down' | 'left' | 'right' | 'none';

export const iconMeasures = {
    tiny: 16,
    medium: 32,
    large: 64
} as const;

export type IconSize = keyof typeof iconMeasures;

const svgIcons = {
    arrow: 'arrow',
    bom: 'bom',
    building: 'building',
    burger: 'burger',
    cancel: 'cancel',
    customer: 'customer',
    dashboard: 'dashboard',
    ellipsis: 'ellipsis',
    file: 'file',
    fullscreen: 'fullscreen',
    kebab: 'kebab',
    lightning: 'lightning',
    plan: 'plan',
    project: 'project',
    risk: 'risk',
    search: 'search',
    settings: 'settings',
    template: 'template',
    trash: 'trash',
    user: 'user',
    wind: 'wind',
    default: ''
} as const;

export type SvgIconTypes = keyof typeof svgIcons;
