import { ChangeDetectionStrategy, Component, Signal, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AuthService, UserService } from 'src/app/core/services';
import { ButtonComponent, SvgIconComponent } from 'src/app/shared/components';
import { UserCardService } from '../../services';
import { User } from 'src/app/shared/interfaces';

@Component({
    standalone: true,
    imports: [SvgIconComponent, ButtonComponent, TranslateModule],
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardComponent {
    private readonly userCardService = inject(UserCardService);
    private readonly authService = inject(AuthService);
    private readonly userService = inject(UserService);

    userData: Signal<User> = this.userService.userData;

    /**
     * @description - Close the user card
     */
    closeCard(): void {
        this.userCardService.toggleUserCard();
    }

    /**
     * @description - Logout the current logged in user
     */
    logout(): void {
        this.userCardService.toggleUserCard();
        this.authService.logout();
    }
}
