import { SvgIconTypes } from 'src/app/shared/components/svg-icon';

export interface NavItem {
    label: string;
    key: string;
    iconName?: SvgIconTypes;
    isSubMenu?: boolean;
    subMenuName?: string;
}

interface SectionsDictionary<T> {
    [key: string]: T;
}

export class NavSections {
    static readonly primarySection: NavItem[] = [
        { label: 'Dashboard', key: 'dashboard', iconName: 'dashboard' },
        { label: 'Customer', key: 'customer', iconName: 'customer' },
        { label: 'Projects', key: 'project', iconName: 'project' },
        { label: 'Buildings', key: 'dpobject', iconName: 'building' },
        { label: 'All Files', key: 'dpitem', iconName: 'file' },
        { label: 'Templates', key: 'templates', iconName: 'template' }
    ];

    static readonly secondarySection: NavItem[] = [
        { label: 'Employee/Guest', key: 'admin/coworker', iconName: 'user' },
        { label: 'Settings', key: 'settings', iconName: 'settings', subMenuName: 'settingsSubMenu' },
        { label: 'Support/Help', key: 'support', iconName: 'lightning', subMenuName: 'supportSubMenu' }
    ];

    static readonly tertiarySection: NavItem[] = [{ label: 'Trash', key: 'trashcan', iconName: 'trash' }];

    static readonly settingsSubMenu: NavItem[] = [
        { label: 'Company', key: 'preferences/company', isSubMenu: true },
        { label: 'Licenses', key: 'preferences/licenses', isSubMenu: true },
        { label: 'Download', key: 'preferences/download', isSubMenu: true },
        { label: 'Company Merge', key: 'preferences/merge', isSubMenu: true },
        { label: 'Language', key: 'preferences/language', isSubMenu: true }
    ];

    static readonly supportSubMenu: NavItem[] = [
        { label: 'First Use', key: 'support/view/firstUse', isSubMenu: true },
        { label: 'Contact', key: 'support/view/contact', isSubMenu: true },
        { label: 'Learn', key: 'support/view/learn', isSubMenu: true },
        { label: 'Terms of Use', key: 'support/view/legal', isSubMenu: true }
    ];

    static readonly sections: SectionsDictionary<NavItem[]> = {
        primarySection: NavSections.primarySection,
        secondarySection: NavSections.secondarySection,
        tertiarySection: NavSections.tertiarySection,
        settingsSubMenu: NavSections.settingsSubMenu,
        supportSubMenu: NavSections.supportSubMenu
    };
}
