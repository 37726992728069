import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Routes, provideRouter } from '@angular/router';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppComponent } from './app/app.component';
import { initializer } from './app/core/config/keycloak-init.config';
import { languageGuard } from './app/core/guards/language.guard';
import { NoAuthGuard } from './app/core/guards/no-auth.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './app/shared/utils';

/**
 * @description - Dashboard routes
 */
const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '' },

    {
        path: '',
        canActivate: [NoAuthGuard, languageGuard],
        canActivateChild: [NoAuthGuard],
        component: AppComponent
    },

    { path: '**', pathMatch: 'full', redirectTo: '' }
];

/**
 * @description - Keycloak initializer config object
 */
const keyCloakConfig = {
    provide: APP_INITIALIZER,
    useFactory: initializer,
    multi: true,
    deps: [KeycloakService]
};

/**
 * @description - List of Initializers during the application bootstrap
 */
const initializers = [keyCloakConfig];

/**
 * @description - List of Modules to import in the whole application
 */
const modules = [
    HttpClientModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        }
    })
];

const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes), ...initializers, provideAnimations(), importProvidersFrom(...modules)]
};

bootstrapApplication(AppComponent, appConfig).catch((e) => console.error(e));
