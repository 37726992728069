import { Injectable, Signal, signal } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    private overlayStateSignal = signal<boolean>(false);

    /**
     * @description - Expose the overlay state as a readonly signal.
     * @returns - A Signal<boolean> with the overlay state.
     */
    get overlayStateValue(): Signal<boolean> {
        return this.overlayStateSignal.asReadonly();
    }

    /**
     * @description - Toggle the overlay state.
     */
    toggleOverlayState(): void {
        this.overlayStateSignal.set(!this.overlayStateSignal());
    }

    /**
     * @description - Set the overlay state to true.
     */
    showOverlay(): void {
        this.overlayStateSignal.set(true);
    }
}
