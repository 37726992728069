<ng-template>
    <div class="shadow-md bg-primary-realwhite">
        @for (option of options(); track option) {
            <div
                class="px-4 py-1 cursor-pointer hover:bg-grey-table hover:text-primary-red text-secondary-text"
                (click)="handleSelectedOption(option)"
            >
                <span>{{ 'common.dropMenu.options.' + option | translate }}</span>
            </div>
        }
    </div>
</ng-template>
