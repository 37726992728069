<section class="flex flex-col space-y-10">
    <!-- Notifications (Info Notes) -->
    @defer (when notificationData().loaded) {
        <app-info-notes-container [notifications]="notificationData().items"></app-info-notes-container>
    } @placeholder (minimum 1.5s) {
        <app-loader-container>
            <h3 class="sm:text-primary-realwhite md:text-primary-realwhite lg:text-primary-realwhite">
                {{ 'section.dashboard.infoNotes.title' | translate }}
            </h3>
        </app-loader-container>
    }

    <!-- History Table -->
    @defer (when historyData().loaded) {
        <app-table
            [dataSource]="historyData().items"
            [totalItems]="historyData().totalCount"
            [headers]="headers"
            [searchBox]="false"
            [pagination]="true"
            tableTitle="history"
        ></app-table>
    } @placeholder (minimum 1.5s) {
        <app-loader-container>
            <h3 id="section-title">{{ 'common.table.title.' + 'history' | translate }}</h3>
        </app-loader-container>
    }
</section>
