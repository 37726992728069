import { Component, computed, inject, signal } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { ButtonComponent } from 'src/app/shared/components';
import { LanguageLegacyService } from '../../../core/services';
import { Languages } from '../../../shared/constants';
import { LanguageInfo, LANGUAGES_OPTIONS } from '../../constants';
import { ClickOutsideDirective } from 'src/app/shared/directives';

@Component({
    standalone: true,
    imports: [ButtonComponent, ClickOutsideDirective],
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent {
    languageOptions = LANGUAGES_OPTIONS;
    isDropdownOpen = signal<boolean>(false);

    private translateService = inject(TranslateService);
    private languageLegacyService = inject(LanguageLegacyService);

    selectedLanguage = computed(
        () => this.languageOptions.find((language: LanguageInfo) => language.id === this.currentLangId()) as LanguageInfo
    );

    private currentLangId = toSignal<string, string>(this.translateService.onLangChange.pipe(map((evt: LangChangeEvent) => evt.lang)), {
        initialValue: this.translateService.currentLang ?? this.translateService.getDefaultLang() ?? Languages.ENGLISH
    });

    /**
     * @description - Toggle the dropdown menu.
     */
    toggleDropdown(): void {
        this.isDropdownOpen.set(!this.isDropdownOpen());
    }

    /**
     * @param selectedLanguage - The selected language from the dropdown menu when the user clicks on it
     */
    changeLanguage(selectedLanguage: string): void {
        const nextLanguage = this.languageOptions.find((language: LanguageInfo) => language.key === selectedLanguage) as LanguageInfo;
        this.translateService.use(nextLanguage.id);
        this.languageLegacyService.setLegacyLanguage(nextLanguage.key).subscribe();

        this.toggleDropdown();
    }

    /**
     * @description - Close the dropdown menu when the user clicks outside of it
     */
    clickedOutside(): void {
        this.isDropdownOpen.set(false);
    }
}
