import { Component, computed, input } from '@angular/core';
import { TableCellData } from 'src/app/shared/interfaces';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { LinkBuilder } from 'src/app/shared/utils/LinkBuilderUtils/link-builder.utils';

@Component({
    standalone: true,
    imports: [SvgIconComponent],
    selector: 'app-table-cell',
    templateUrl: './table-cell.component.html'
})
export class TableCellComponent {
    cell = input.required<TableCellData>({
        alias: 'cellData'
    });

    cellContent = computed(() => this.cell().row[this.cell().key]);
    cellIcon = computed(() => this.cell().row.sortKey);

    /**
     * @description - Method for handle the row click and redirect the user to the correct page.
     * @param cellData - The cell clicked by the user.
     */
    handleRowClick(cell: TableCellData): void {
        LinkBuilder.buildLinkFromTableItemType(cell.row);
    }
}
