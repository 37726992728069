import { Component, computed, inject } from '@angular/core';

import { OverlayService } from '../../../shared/services';

import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from 'src/app/dashboard/components/dashboard.component';
import { BlurOverlayComponent } from 'src/app/shared/components';
import { FooterComponent, HeaderComponent } from '../';

@Component({
    standalone: true,
    imports: [HeaderComponent, DashboardComponent, BlurOverlayComponent, FooterComponent, TranslateModule],
    selector: 'app-site-layout',
    templateUrl: './site-layout.component.html'
})
export class SiteLayoutComponent {
    overlayService = inject(OverlayService);

    isOverlayTriggered = computed(() => this.overlayService.overlayStateValue());
}
