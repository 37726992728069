import { Directive, ElementRef, HostListener, OnDestroy, ViewContainerRef, input } from '@angular/core';
import { DropdownPanel } from './dropdown-panel.interface';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { merge, Observable, Subscription } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[appDropdownTriggerFor]'
})
export class DropdownTriggerForDirective implements OnDestroy {
    private isDropdownOpen = false;
    private overlayRef!: OverlayRef;
    private dropdownClosingActionsSub = Subscription.EMPTY;

    dropdownPanel = input.required<DropdownPanel>({
        alias: 'appDropdownTriggerFor'
    });
    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef
    ) {}

    @HostListener('click')
    /**
     * @description - Toggles the dropdown panel
     */
    toggleDropdown(): void {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }

    /**
     * @description - Opens the dropdown panel using cdk-overlay and subscribes to the closing actions of the dropdown panel to destroy it when needed
     */
    openDropdown(): void {
        this.isDropdownOpen = true;
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetX: 45
                    }
                ])
        });

        const templatePortal = new TemplatePortal(this.dropdownPanel().templateRef, this.viewContainerRef);
        this.overlayRef.attach(templatePortal);

        this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => this.destroyDropdown());
    }

    /**
     * @returns - An observable that emits when the dropdown panel is closed
     */
    private dropdownClosingActions(): Observable<MouseEvent | void> {
        const backdropClick$ = this.overlayRef.backdropClick();
        const detachment$ = this.overlayRef.detachments();
        const dropdownClosed = this.dropdownPanel().closed;

        return merge(backdropClick$, detachment$, dropdownClosed);
    }

    /**
     * @returns - Destroys the dropdown panel and unsubscribes from the closing actions observable if the dropdown panel is open and the overlayRef is defined (it should be)
     */
    private destroyDropdown(): void {
        if (!this.overlayRef || !this.isDropdownOpen) {
            return;
        }

        this.dropdownClosingActionsSub.unsubscribe();
        this.isDropdownOpen = false;
        this.overlayRef.detach();
    }

    /**
     * @description Destroys the dropdown panel if it is open when the directive is destroyed
     */
    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}
