import { Component } from '@angular/core';
import { SpinnerLoaderComponent } from 'src/app/shared/components';

@Component({
    standalone: true,
    imports: [SpinnerLoaderComponent],
    selector: 'app-loader-container',
    templateUrl: './loader-container.component.html'
})
export class LoaderContainerComponent {}
