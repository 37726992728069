import { state, style, trigger, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { Notification } from '../../../../dashboard/interfaces';

const modalEnterAnimation = trigger('modalEnterAnimation', [
    state('void', style({ transform: 'scale(0.7)', opacity: 0 })),
    state('*', style({ transform: 'scale(1)', opacity: 1 })),
    transition('void => *', animate('300ms ease-out')),
    transition('* => void', animate('200ms ease-in'))
]);

@Component({
    standalone: true,
    imports: [ButtonComponent],
    selector: 'app-info-note-modal',
    templateUrl: './info-note-modal.component.html',
    animations: [modalEnterAnimation]
})
export class InfoNoteModalComponent {
    @Input() note: Notification | null = null;

    @Output() closeNoteModal = new EventEmitter<boolean>(false);

    closeModal(toDelete?: boolean) {
        this.closeNoteModal.emit(toDelete);
    }
}
