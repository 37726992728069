import { KeycloakService } from 'keycloak-angular';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private keycloakService = inject(KeycloakService);

    login() {
        this.keycloakService.login();
    }

    logout() {
        this.keycloakService.logout(environment.logoutPath);
    }
}
