import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethod, RequestOptions } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { LANGUAGES_OPTIONS, LanguageInfo } from '../../../layout/constants';

const legacyDEHNProjectApiUrl = environment.legacyDEHNProjectApiUrl ?? '';

@Injectable({
    providedIn: 'root'
})
export class LanguageLegacyService extends ApiService {
    languageOptions = LANGUAGES_OPTIONS;

    setLegacyLanguageByISOCode(languageId: string): void {
        const nextLanguage = this.languageOptions.find((language: LanguageInfo) => language.id === languageId) as LanguageInfo;
        this.setLegacyLanguage(nextLanguage.key);
    }
    /**
     * @param language The language selected to send to DEHN project language legacy service to update the user's language
     * @returns A void Observable with empty response after patching the user's language
     */
    setLegacyLanguage(language: string): Observable<void> {
        const patchUrl = `${legacyDEHNProjectApiUrl}/user-settings/v1/language/${language.toLocaleUpperCase()}`;

        const requestOptions: RequestOptions = {
            method: HttpMethod.PATCH,
            url: patchUrl
        };

        return this.request<void>(requestOptions, 'updateUserLanguage');
    }
}
