import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '../services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard extends KeycloakAuthGuard {
    private readonly userService = inject(UserService);

    constructor(
        protected override readonly router: Router,
        protected readonly keycloak: KeycloakService
    ) {
        super(router, keycloak);
    }

    /**
     * @returns - Check if the user is authenticated. If not, redirect to the login page.
     */
    async isAccessAllowed(): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloak.login();
            return false;
        }

        this.setUserData();
        return true;
    }

    /**
     * @description - Set the user data in the user service.
     */
    private setUserData(): void {
        this.userService.fetchUserData();
    }
}
