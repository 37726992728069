<div class="relative inline-block" appClickOutside (clickOutside)="clickedOutside()">
    <app-button
        type="secondary"
        size="small"
        icon="arrow"
        iconSize="tiny"
        [iconDirection]="isOpen() ? 'down' : 'up'"
        (click)="toggleDropdown()"
    >
        {{ selectedOption() }}
    </app-button>

    @if (isOpen()) {
        <ul class="absolute z-10 p-0 ml-1 list-none hover:cursor-pointer shadow-md">
            @for (option of dropdownOptions(); track option) {
                <li
                    class="px-5 py-1 cursor-pointer text-secondary-text bg-primary-realwhite hover:bg-secondary-gainsboro hover:text-primary-red"
                    (click)="selectOption(option)"
                >
                    {{ option }}
                </li>
            }
        </ul>
    }
</div>
