import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchTableService {
    private _search$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    /**
     * @returns The search query as an observable to filter the table data.
     */
    get search$(): Observable<string> {
        return this._search$.asObservable();
    }

    /**
     * @param searchQuery The search query to filter the table data.
     */
    set setSearchQuery(searchQuery: string) {
        this._search$.next(searchQuery);
    }
}
