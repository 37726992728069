import { History } from '../../dashboard/interfaces/history.interface';
import { PageInfo } from '../services/pagination';

export type DataType = string | number | boolean | undefined | null;
export interface KeyedData {
    [key: string]: DataType;
}

export interface TableHeader {
    key: string;
    label: string;
    sortableKey?: string;
    isSortable: boolean;
}

export interface TableCellData {
    key: string;
    row: TableData;
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc'
}

export interface SortingInfo {
    sort: string;
    order: SortOrder;
}

export interface TableFilters {
    paginationInfo: PageInfo;
    sortingInfo: SortingInfo;
    [key: string]: PageInfo | SortingInfo;
}

//TODO: Update this with future data types eg:(customer,projects,buildings,files,etc)
export type TableData = History;
