import { first, tap } from 'rxjs';
import { HttpMethod, RequestOptions, User } from 'src/app/shared/interfaces';
import { UriUtils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

import { Injectable, Signal, inject, signal } from '@angular/core';

import { ApiService } from '../../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiService = inject(ApiService);

    private userDataSignal = signal<User>({
        companyName: '',
        eMail: '',
        firstName: '',
        lastName: '',
        language: ''
    });

    /**
     * @description - Expose the user data signal as readonly (PUBLIC API).
     */
    userData: Signal<User> = this.userDataSignal.asReadonly();

    /**
     * @description - Fetch the user data and set the user data signal.
     */
    fetchUserData(): void {
        const requestOptions: RequestOptions = {
            method: HttpMethod.GET,
            url: this.buildUrl()
        };

        const userData$ = this.apiService.request<User>(requestOptions, `fetchUserData`).pipe(
            first(),
            tap((userData) => this.userDataSignal.set(userData))
        );

        userData$.subscribe();
    }

    /**
     * @param segments - The segments to append to the url
     * @returns - The url for the user service with the segments appended
     */
    private buildUrl(...segments: string[]): string {
        return UriUtils.appendParts(environment.serverUrl, '/v1/user', ...segments);
    }
}
