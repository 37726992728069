import { Component, inject } from '@angular/core';
import { OverlayService } from '../../services';
import { ModalControl } from '../../interfaces';
import { ButtonComponent } from '../button/button.component';
import { ModalComponent } from '../modal/modal.component';
import { LinkBuilder } from '../../utils/LinkBuilderUtils/link-builder.utils';

@Component({
    standalone: true,
    imports: [ButtonComponent, ModalComponent],
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html'
})
export class SplashScreenComponent implements ModalControl {
    modalState = true;

    private blurOverlayService = inject(OverlayService);

    /**
     * @description - Closes the modal and removes the blur overlay
     */
    handleCloseModal() {
        this.modalState = false;
        this.blurOverlayService.toggleOverlayState();
    }

    /**
     * @description - Redirects the user to the license page
     */
    handleLicenseButtonClick() {
        LinkBuilder.redirectToLegacyProject('admin/signup');
    }
}
