import { Component, EventEmitter, Input, Output, inject, signal } from '@angular/core';
import { OverlayService } from '../../services';
import { ButtonComponent } from '../button/button.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { InfoNoteModalComponent } from './info-note-modal/info-note-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { Notification } from '../../../dashboard/interfaces';
@Component({
    standalone: true,
    imports: [ButtonComponent, InfoNoteModalComponent, AsyncPipe, NgClass, TranslateModule],
    selector: 'app-info-note',
    templateUrl: './info-note.component.html'
})
export class InfoNoteComponent {
    @Input() note: Notification | null = null;
    @Input() specialNote: boolean | null = false;

    @Output() triggerDeleteInfoNote = new EventEmitter<string>();

    noteModalState = signal<boolean>(false);

    private readonly blurOverlayService = inject(OverlayService);

    openNoteModal() {
        this.blurOverlayService.toggleOverlayState();
        this.noteModalState.set(true);
    }

    closeNoteModal(toDelete: boolean) {
        this.blurOverlayService.toggleOverlayState();
        this.noteModalState.set(false);
        if (toDelete) {
            this.closeInfoNote();
        }
    }

    closeInfoNote() {
        this.triggerDeleteInfoNote.emit(this.note?.id);
    }
}
