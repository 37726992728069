import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconDirection, IconSize, IconStyle, SvgIconTypes } from '../svg-icon';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ButtonSize, ButtonStyle, IconButtonPosition } from './';

@Component({
    standalone: true,
    imports: [NgClass, SvgIconComponent],
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input({ required: true }) type: ButtonStyle = 'primary';

    @Input() icon?: SvgIconTypes;
    @Input() iconSize: IconSize = 'medium';
    @Input() iconDirection: IconDirection = 'none';
    @Input() iconVariant: IconStyle = 'default';
    @Input() iconColor = 'currentColor';

    @Input() iconPosition: IconButtonPosition = 'right';
    @Input() size: ButtonSize = 'normal';
    @Input() disabled = false;
    @Input() knob = false;
    @Input() solid = false;

    /**
     * @description - Get the classes to be applied to the button
     * @returns -  Array of classes to be applied to the button
     */
    get classes(): string[] {
        const baseClass = 'button';
        const btnIcon = 'button-icon';
        const btnIconClass = this.icon ? btnIcon : '';
        const btnIconKnob = this.knob ? `${btnIcon}-knob` : '';
        const typeClass = `${baseClass}-${this.type}`;
        const variantClass = this.solid ? `${baseClass}-${this.type}-solid` : '';
        const sizeClass = this.size === 'small' ? `${baseClass}-small` : '';
        const iconPositionClass = this.icon && this.iconPosition === 'left' ? `${btnIconClass}-left` : '';
        const disabledClass = this.disabled ? `${baseClass}-disabled` : '';

        return [baseClass, typeClass, sizeClass, variantClass, btnIconClass, btnIconKnob, iconPositionClass, disabledClass].filter(Boolean);
    }
}
