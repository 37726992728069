<section class="flex flex-col space-y-9">
  <!--Searchbox container-->
  <div class="flex justify-between">
    <h3 id="section-title">
      {{ "common.table.title." + title() | translate }}
    </h3>
    @if (searchBox()) {
      <app-searchbox></app-searchbox>
    }
  </div>

  <!--Table-->
  @if (tableData().length > 0) {
    <table
      id="generic-table"
      class="w-full border-collapse border-none bg-primary-realwhite text-left shadow-lg"
      [@rowsAnimation]
    >
      <thead>
        <tr>
          @for (header of headers(); track header.key) {
            <th class="p-2 font-semibold">
              <app-table-header [header]="header"></app-table-header>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of tableData(); track row.id) {
          <tr>
            @for (header of headers(); track header.key) {
              <!--Table cell component-->
              <td [ngClass]="{ 'min-w-[6rem]': header.key !== 'type' }">
                <app-table-cell
                  [cellData]="{ key: header.key, row }"
                ></app-table-cell>
              </td>
            }
            <!--Context menu trigger component-->
            <td class="h-4 w-4 cursor-pointer border-none px-2 py-3">
              <app-context-menu-trigger [row]="row"></app-context-menu-trigger>
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <h4 class="py-4 text-center">
      {{ "common.table.noData" | translate }}
    </h4>
  }

  <!--Pagination container-->
  @if (pagination() && tableData().length > 0) {
    <div class="flex justify-center">
      <app-pagination></app-pagination>
    </div>
  }
</section>
