import { KeyedData } from 'src/app/shared/interfaces';

export interface History extends KeyedData {
    buildingId?: string;
    companyGroupId: string;
    customerId?: string;
    id: string;
    lastUpdated: string;
    mimetype?: string;
    name: string;
    projectId?: string;
    sortKey: HistoryType;
    version?: string;
    lastUpdateUser: string;
    projectName?: string;
    planName?: string;
    planId?: string;
    previewImage?: string;
}

export enum HistoryType {
    Customer = 'customer',
    File = 'file',
    Project = 'project',
    Building = 'building',
    BOM = 'bom'
}
