import { Component, computed, inject, signal } from '@angular/core';
import { HamburgerMenuService, UserCardService } from '../../services';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, ImageComponent, SvgIconComponent } from 'src/app/shared/components';
import { UserCardComponent } from '../user-card/user-card.component';
import { ClickOutsideDirective } from 'src/app/shared/directives';
import { HamburgerMenuComponent } from '../hamburger-menu/hamburger-menu.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Component({
    standalone: true,
    imports: [
        UserCardComponent,
        SvgIconComponent,
        ImageComponent,
        ButtonComponent,
        NgClass,
        TranslateModule,
        ClickOutsideDirective,
        HamburgerMenuComponent
    ],
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    hamburgerMenuService = inject(HamburgerMenuService);
    userCardService = inject(UserCardService);
    breakpointObserver = inject(BreakpointObserver);

    isUserIconHovered = signal<boolean>(false);

    isUserCardOpen = computed(() => this.userCardService.userCardStateValue());
    isHamburgerMenuOpen = computed(() => this.hamburgerMenuService.hamburgerMenuStateValue());
    isSmallScreen = toSignal<boolean, boolean>(this.breakpointObserver.observe(Breakpoints.XSmall).pipe(map((result) => result.matches)), {
        initialValue: false
    });

    /**
     * @description - Toggle the hamburger menu.
     */
    toggleHamburgerMenu(): void {
        this.hamburgerMenuService.toggle();
    }

    /**
     * @description - Handle hover event of the user info icon.
     */
    handleMouseEnter(): void {
        this.isUserIconHovered.set(true);
    }

    /**
     * @description - Handle the mouse leave event of the user info icon.
     */
    handleMouseLeave(): void {
        this.isUserIconHovered.set(false);
    }

    /**
     * @description - Open the user info card.
     */
    toggleUserInfoCard(): void {
        this.userCardService.toggleUserCard();
    }

    /**
     * @description - Close the user info card or the hamburger menu if it is open and the user clicks outside of these components..
     */
    clickedOutside(): void {
        if (this.isUserCardOpen()) {
            this.userCardService.closeUserCard();
        }
        if (this.isHamburgerMenuOpen()) {
            this.hamburgerMenuService.closeSubMenu();
            this.hamburgerMenuService.toggle();
        }
    }
}
