<article
    class="relative left-0 z-[1] overflow-hidden bg-primary-realwhite p-4 shadow-lg after:absolute after:bottom-0 after:block after:h-8 after:w-full after:bg-[linear-gradient(to_bottom,rgba(255,255,255,0),#ffffff)] after:content-[''] max-md:h-40 max-sm:h-40"
    [ngClass]="{ 'h-36 w-96': specialNote, 'h-60': !specialNote }"
>
    <div class="flex flex-col space-y-4">
        <!--First row container-->
        <div class="flex justify-between gap-8">
            <div class="flex flex-col gap-y-2">
                <h4 id="message-title" class="m-0 break-all text-xl">
                    {{ note ? note.title : ('section.dashboard.infoNotes.noData.title' | translate) }}
                </h4>
                @if (note) {
                    <p id="message-time" class="m-0 text-xs">{{ note._ts }}</p>
                }
            </div>
            <!--Actions button container-->
            @if (!specialNote) {
                <div class="flex gap-x-2">
                    <app-button
                        type="secondary"
                        icon="trash"
                        iconSize="tiny"
                        size="small"
                        [knob]="true"
                        (click)="closeInfoNote()"
                    ></app-button>
                    <app-button
                        type="secondary"
                        icon="fullscreen"
                        iconSize="tiny"
                        size="small"
                        [knob]="true"
                        (click)="openNoteModal()"
                    ></app-button>
                </div>
            }
        </div>
        <p id="info-note-body">
            {{ note ? note.content : ('section.dashboard.infoNotes.noData.message' | translate) }}
        </p>
    </div>
</article>

@if (noteModalState()) {
    <app-info-note-modal [note]="note" (closeNoteModal)="closeNoteModal($event)"> </app-info-note-modal>
}
