<div class="relative">
    <input
        type="text"
        class="p-2 border-b border-solid outline-none border-b-secondary-text text-secondary-text md:h-9 md:w-64 lg:h-9 lg:w-72"
        [(ngModel)]="searchQuery"
        [placeholder]="'common.table.searchBox.placeholder' | translate"
        (keyup.enter)="onSearch()"
    />

    <span class="absolute top-0 flex items-center h-full space-x-2 right-4">
        @if (searchQuery.length > 0) {
            <app-svg-icon
                icon="cancel"
                size="tiny"
                variant="default"
                direction="none"
                class="hover:cursor-pointer"
                (click)="onClear()"
            ></app-svg-icon>
        }
        <app-svg-icon
            icon="search"
            size="tiny"
            variant="default"
            direction="none"
            class="hover:cursor-pointer"
            (click)="onSearch()"
        ></app-svg-icon>
    </span>
</div>
